"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.renderSpecial = exports.query = exports.default = void 0;
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Cross = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/Cross"));
var _Plus = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/Plus"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
var _UnreachableCaseError = require("@oberoninternal/travelbase-ds/entities/UnreachableCaseError");
var _Package = _interopRequireDefault(require("@oberoninternal/travelbase-ds/svg/Package.svg"));
var _Special = _interopRequireDefault(require("@oberoninternal/travelbase-ds/svg/Special.svg"));
var _grid = require("@rebass/grid");
var _client = require("@apollo/client");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _reactLoadingSkeleton = _interopRequireDefault(require("react-loading-skeleton"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _graphql = require("../generated/graphql");
var _RoundButton = require("@oberoninternal/travelbase-ds/components/action/RoundButton");
var _Stack = _interopRequireDefault(require("./Stack"));
var __jsx = _react.default.createElement;
const query = exports.query = (0, _client.gql)`
    query Special($id: ID!) {
        special(id: $id) {
            id
            description
            landingPageUrl
            name
        }
    }
`;
const renderSpecial = ({
  type,
  data,
  loading,
  price,
  originalPrice,
  trip,
  onAddSpecialTrip,
  onRemoveSpecialTrip: onRemoveSpecial,
  hasSelectedSpecial
}) => {
  switch (type) {
    case _graphql.TripTypeEnum.Special:
      {
        if (loading) {
          return __jsx(_reactLoadingSkeleton.default, null);
        }
        if (!data?.special?.id) {
          return null;
        }
        const {
          special: {
            description,
            name,
            landingPageUrl
          }
        } = data;
        return __jsx(InnerSpecial, {
          icon: originalPrice ? __jsx(_Special.default, null) : __jsx(_Package.default, null),
          description: description,
          title: name,
          price: price,
          originalPrice: originalPrice,
          button: __jsx(_react.default.Fragment, null, __jsx(_Button.default, {
            variant: hasSelectedSpecial ? 'outline' : 'primary',
            onClick: hasSelectedSpecial ? onRemoveSpecial : () => onAddSpecialTrip(trip),
            className: "gt-m"
          }, hasSelectedSpecial ? __jsx(_react.default.Fragment, null, __jsx(_Cross.default, {
            style: {
              marginRight: '0.8rem'
            }
          }), __jsx(_reactIntl.FormattedMessage, {
            id: "iY8dkE",
            defaultMessage: [{
              "type": 0,
              "value": "verwijderen"
            }]
          })) : __jsx(_react.default.Fragment, null, __jsx(_Plus.default, {
            style: {
              marginRight: '0.8rem'
            }
          }), __jsx(_reactIntl.FormattedMessage, {
            id: "M7ZsU/",
            defaultMessage: [{
              "type": 0,
              "value": "kiezen"
            }]
          }))), __jsx(_RoundButton.StyledRoundButton, {
            variant: hasSelectedSpecial ? 'outline' : 'primary',
            onClick: hasSelectedSpecial ? onRemoveSpecial : () => onAddSpecialTrip(trip),
            className: "lt-m"
          }, hasSelectedSpecial ? __jsx(_react.default.Fragment, null, __jsx(_Cross.default, null)) : __jsx("div", null, __jsx(_Plus.default, null)))),
          landingPageUrl: landingPageUrl
        });
      }
    case _graphql.TripTypeEnum.LastMinute:
      {
        return __jsx(InnerSpecial, {
          icon: __jsx(_Special.default, null),
          title: __jsx(_reactIntl.FormattedMessage, {
            id: "c8ryws",
            defaultMessage: [{
              "type": 0,
              "value": "Last-minute korting"
            }]
          }),
          description: __jsx(_reactIntl.FormattedMessage, {
            id: "ge5iLq",
            defaultMessage: [{
              "type": 0,
              "value": "Op het laatste moment een vakantie of weekendje weg boeken? Texel heeft een uitgebreid aanbod last minute aanbiedingen, waarmee u profiteert van aantrekkelijke kortingen!"
            }]
          }),
          originalPrice: originalPrice,
          price: price
        });
      }
    case _graphql.TripTypeEnum.EarlyBooking:
      {
        return __jsx(InnerSpecial, {
          icon: __jsx(_Special.default, null),
          title: __jsx(_reactIntl.FormattedMessage, {
            id: "Vuz1Pn",
            defaultMessage: [{
              "type": 0,
              "value": "Vroegboekkorting"
            }]
          }),
          description: __jsx(_reactIntl.FormattedMessage, {
            id: "hm0N82",
            defaultMessage: [{
              "type": 0,
              "value": "Hier komt de vroegboektext te staan"
            }]
          }),
          originalPrice: originalPrice,
          price: price
        });
      }
    default:
      throw new _UnreachableCaseError.UnreachableCaseError(type);
  }
};
exports.renderSpecial = renderSpecial;
const SpecialTrip = ({
  trip,
  booking,
  onAddSpecialTrip,
  onRemoveSpecialTrip
}) => {
  const {
    specialId,
    type,
    price,
    originalPrice
  } = trip;
  if (type === _graphql.TripTypeEnum.Regular) {
    throw new Error(`This trip is a regular trip. Make sure only special trips get passed to this component`);
  }
  // query will be skipped if there's no specialId, hence the assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const {
    data,
    loading
  } = (0, _graphql.useSpecialQuery)({
    variables: {
      id: specialId
    },
    skip: !specialId
  });
  return renderSpecial({
    type,
    data,
    loading,
    booking,
    price,
    originalPrice,
    trip,
    onAddSpecialTrip,
    onRemoveSpecialTrip,
    hasSelectedSpecial: booking.trip?.specialId === specialId
  });
};
const InnerSpecial = ({
  title,
  description,
  price,
  landingPageUrl,
  button,
  icon,
  originalPrice
}) => __jsx(Container, null, __jsx(_grid.Box, {
  pr: 4,
  flex: 1
}, __jsx(TitleWrapper, null, icon, __jsx(Title, null, title)), __jsx(StyledBody, null, description, ' ', landingPageUrl && __jsx("a", {
  href: landingPageUrl,
  target: "_blank",
  rel: "noreferrer noopener"
}, __jsx(_reactIntl.FormattedMessage, {
  id: "EbRgER",
  defaultMessage: [{
    "type": 0,
    "value": "Lees meer"
  }]
})))), price || originalPrice ? __jsx(_grid.Flex, {
  alignItems: "center",
  justifyContent: "space-between"
}, __jsx(_grid.Flex, {
  alignItems: "flex-end",
  mr: 4
}, __jsx(_Stack.default, {
  variant: "inline",
  spacing: 3,
  alignItems: "center"
}, originalPrice && __jsx(_Body.default, {
  variant: "small"
}, __jsx(_reactIntl.FormattedMessage, {
  id: "K7xxG2",
  defaultMessage: [{
    "type": 0,
    "value": "van "
  }, {
    "type": 1,
    "value": "price"
  }],
  values: {
    price: __jsx(OriginalPrice, null, __jsx(_reactIntl.FormattedNumber, {
      format: "EUR",
      value: originalPrice
    }))
  }
})), __jsx(_Body.default, {
  variant: "large"
}, originalPrice && __jsx(_react.default.Fragment, null, __jsx(_reactIntl.FormattedMessage, {
  id: "GKy00d",
  defaultMessage: [{
    "type": 0,
    "value": "voor"
  }]
}), ' '), price && __jsx(Price, null, __jsx(_reactIntl.FormattedNumber, {
  format: "EUR",
  value: price
})))))) : null, button);
var _default = exports.default = SpecialTrip;
const TitleWrapper = _styledComponents.default.div.withConfig({
  displayName: "SpecialTrip__TitleWrapper",
  componentId: "sc-1200aw9-0"
})(["display:flex;align-items:center;color:", ";"], ({
  theme
}) => theme.colors.primary['80']);
const Price = _styledComponents.default.span.withConfig({
  displayName: "SpecialTrip__Price",
  componentId: "sc-1200aw9-1"
})(["", ";font-weight:600;"], ({
  theme
}) => (0, _theme.getFontCss)(theme.fontSizes.body.huge));
const OriginalPrice = _styledComponents.default.span.withConfig({
  displayName: "SpecialTrip__OriginalPrice",
  componentId: "sc-1200aw9-2"
})(["", ";text-decoration:line-through;color:", ";"], ({
  theme
}) => (0, _theme.getFontCss)(theme.fontSizes.body.regular), ({
  theme
}) => theme.colors.neutral['50']);
const Title = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "SpecialTrip__Title",
  componentId: "sc-1200aw9-3"
})(["font-weight:bolder;margin-left:", ";"], ({
  theme
}) => theme.spacing['30_Small']);
const StyledBody = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "SpecialTrip__StyledBody",
  componentId: "sc-1200aw9-4"
})(["margin-top:0.6rem;max-width:68.8rem;"]);
const Container = _styledComponents.default.div.withConfig({
  displayName: "SpecialTrip__Container",
  componentId: "sc-1200aw9-5"
})(["display:flex;align-items:center;justify-content:space-between;padding:", ";width:100%;border:", " 1px solid;border-radius:0.8rem;@media screen and (max-width:", "){flex-direction:column;align-items:flex-start;}"], ({
  theme
}) => theme.spacing['50_Semi'], ({
  theme
}) => theme.colors.neutral['20'], ({
  theme
}) => theme.mediaQueries.s);
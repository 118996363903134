"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _TextButton = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/TextButton"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _useQueryParams = require("use-query-params");
var _Tooltip = _interopRequireDefault(require("./designsystem/Tooltip"));
var _useQueryParams2 = _interopRequireDefault(require("../hooks/useQueryParams"));
var __jsx = _react.default.createElement;
const PlannerInfoText = ({
  tripsInViewCount,
  allTripsCount,
  specialFilterActive
}) => {
  const [, setParams] = (0, _useQueryParams2.default)({
    specialId: _useQueryParams.StringParam
  });
  if (tripsInViewCount === 0 && specialFilterActive) {
    const message = __jsx(_Body.default, {
      variant: "small"
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "SphE0t",
      defaultMessage: [{
        "type": 0,
        "value": "Niet beschikbaar in deze periode. Klik "
      }, {
        "type": 8,
        "value": "button",
        "children": [{
          "type": 0,
          "value": "hier"
        }]
      }, {
        "type": 0,
        "value": " om ander aanbod te tonen"
      }],
      values: {
        button: chunks => __jsx(_TextButton.default, {
          onClick: () => setParams({
            specialId: undefined
          }, 'replaceIn'),
          style: {
            fontSize: 'inherit'
          }
        }, chunks)
      }
    }));
    return __jsx(_react.default.Fragment, null, __jsx(_Tooltip.default, {
      containerProps: {
        className: 'gt-s'
      },
      placement: "right"
    }, message), __jsx(_Tooltip.default, {
      containerProps: {
        className: 'lt-s'
      },
      placement: "auto-end"
    }, message));
  }
  if (allTripsCount > 0 && tripsInViewCount === 0) {
    return __jsx(_Body.default, {
      variant: "small",
      style: {
        marginTop: 'auto',
        marginBottom: 'auto',
        paddingRight: '1rem'
      }
    }, __jsx(_reactIntl.FormattedMessage, {
      id: "eVrBgi",
      defaultMessage: [{
        "type": 0,
        "value": "Beschikbaarheid onbekend in deze periode."
      }]
    }));
  }
  return null;
};
var _default = exports.default = PlannerInfoText;
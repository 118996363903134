"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.plannerQuery = exports.default = void 0;
var _client = require("@apollo/client");
var _graphql = require("../../generated/graphql");
var _usePlannerQuery = _interopRequireDefault(require("../planner/usePlannerQuery"));
const plannerQuery = exports.plannerQuery = (0, _client.gql)`
    query TripPlanner($slug: String!, $start: String!, $end: String!, $persons: Int!, $pets: Int!, $babies: Int!) {
        rentalUnit(slug: $slug) {
            id
            name
            code
            brand
            trips(pets: $pets, persons: $persons, babies: $babies, startDate: $start, endDate: $end) {
                ...PlannerTrip
            }
        }
    }

    fragment PlannerTrip on Trip {
        date
        price
        duration
        type
        originalPrice
        specialId
    }
`;
const useTripPlannerQuery = variables => (0, _usePlannerQuery.default)(variables, _graphql.TripPlannerDocument);
var _default = exports.default = useTripPlannerQuery;
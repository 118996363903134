"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.TripContent = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _useSesame = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useSesame"));
var _useThemeContext = require("@oberoninternal/travelbase-ds/hooks/useThemeContext");
var _Package = _interopRequireDefault(require("@oberoninternal/travelbase-ds/svg/Package.svg"));
var _Special = _interopRequireDefault(require("@oberoninternal/travelbase-ds/svg/Special.svg"));
var _grid = require("@rebass/grid");
var _dynamic = _interopRequireDefault(require("next/dynamic"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _reactLoadingSkeleton = _interopRequireDefault(require("react-loading-skeleton"));
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _customizationClassNames = require("../constants/customizationClassNames");
var _trip = require("../utils/trip");
var _BookingText = _interopRequireDefault(require("./BookingText"));
var _Stack = _interopRequireDefault(require("./Stack"));
var _StickyBar = _interopRequireWildcard(require("./StickyBar"));
var _StickyBarBalloon = _interopRequireDefault(require("./StickyBarBalloon"));
var _InformationCircle = _interopRequireDefault(require("./svg/InformationCircle.svg"));
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const Manager = (0, _dynamic.default)(() => Promise.resolve().then(() => _interopRequireWildcard(require('react-popper'))).then(mod => mod.Manager), {
  loadableGenerated: {
    webpack: () => [require.resolveWeak('react-popper')]
  }
});
const Reference = (0, _dynamic.default)(() => Promise.resolve().then(() => _interopRequireWildcard(require('react-popper'))).then(mod => mod.Reference), {
  loadableGenerated: {
    webpack: () => [require.resolveWeak('react-popper')]
  }
});
const UnitStickyBar = ({
  booking,
  plannerIsOpen,
  children,
  openModal,
  confirmButton,
  className,
  withTransition = false,
  loading,
  hasRelativePosition
}) => {
  const {
    trip
  } = booking;
  return __jsx(StyledStickyBar, {
    className: `${className ?? ''} ${_customizationClassNames.CLASS_STICKY_BAR}`,
    plannerIsOpen: plannerIsOpen,
    visible: !!trip,
    withTransition: withTransition,
    hasRelativePosition: hasRelativePosition
  }, children, __jsx(_grid.Flex, {
    alignItems: "center",
    ml: [null, null, 'auto'],
    width: [1, null, 'auto'],
    justifyContent: ['space-between', null, 'flex-start']
  }, loading && __jsx(_Stack.default, {
    pr: "2rem",
    alignItems: [null, null, 'flex-end']
  }, __jsx(_reactLoadingSkeleton.default, {
    width: 150,
    height: 25
  }), __jsx(_reactLoadingSkeleton.default, {
    width: 200,
    height: 15
  })), __jsx(TripContent, {
    booking: booking,
    plannerIsOpen: plannerIsOpen,
    openModal: openModal,
    loading: loading
  }), confirmButton));
};
const TripContent = ({
  booking,
  plannerIsOpen,
  openModal,
  loading
}) => {
  const {
    arrivalDate,
    departureDate,
    trip
  } = booking;
  const {
    open,
    onToggle,
    onClose,
    ref: outsideClickRef
  } = (0, _useSesame.default)(false, {
    closeOnEscape: true,
    closeOnClickOutside: true
  });
  const theme = (0, _useThemeContext.useThemeContext)();
  return __jsx(_react.default.Fragment, null, !loading && arrivalDate && departureDate && trip && __jsx(_grid.Flex, {
    pr: "2rem",
    flexDirection: "column",
    alignItems: [null, null, 'flex-end'],
    style: {
      position: 'relative'
    },
    ref: outsideClickRef
  }, __jsx(Manager, null, __jsx(Reference, null, ({
    ref
  }) => __jsx(_StickyBar.StickyBarPriceContainer, {
    ref: ref,
    alignItems: "center",
    "data-cy": "stickyBarPriceContainer"
  }, __jsx(_Body.default, {
    variant: "small",
    style: {
      marginRight: '4px'
    }
  }, __jsx("span", {
    className: "gt-s"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "YsuETd",
    defaultMessage: [{
      "type": 2,
      "style": null,
      "value": "duration"
    }, {
      "type": 0,
      "value": " "
    }, {
      "type": 6,
      "pluralType": "cardinal",
      "value": "duration",
      "offset": 0,
      "options": {
        "one": {
          "value": [{
            "type": 0,
            "value": "nacht"
          }]
        },
        "other": {
          "value": [{
            "type": 0,
            "value": "nachten"
          }]
        }
      }
    }, {
      "type": 0,
      "value": " voor"
    }],
    values: {
      duration: (0, _trip.getTripDuration)(arrivalDate, departureDate)
    }
  })), plannerIsOpen && __jsx(_react.default.Fragment, null, __jsx("span", {
    className: "gt-xs lt-s"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "YsuETd",
    defaultMessage: [{
      "type": 2,
      "style": null,
      "value": "duration"
    }, {
      "type": 0,
      "value": " "
    }, {
      "type": 6,
      "pluralType": "cardinal",
      "value": "duration",
      "offset": 0,
      "options": {
        "one": {
          "value": [{
            "type": 0,
            "value": "nacht"
          }]
        },
        "other": {
          "value": [{
            "type": 0,
            "value": "nachten"
          }]
        }
      }
    }, {
      "type": 0,
      "value": " voor"
    }],
    values: {
      duration: (0, _trip.getTripDuration)(arrivalDate, departureDate)
    }
  }), __jsx("br", null))), trip.originalPrice && __jsx(_react.default.Fragment, null, ' ', __jsx(_StickyBar.StickyBarOriginalPrice, null, __jsx(_reactIntl.FormattedNumber, {
    format: "EUR",
    value: trip.originalPrice,
    minimumFractionDigits: 2
  }))), __jsx(_StickyBar.StickyBarPrice, null, ' ', __jsx(_reactIntl.FormattedNumber, {
    format: "EUR",
    value: trip.price,
    minimumFractionDigits: 2
  }))), trip ? __jsx(_StickyBar.StickyBarSvgButton, {
    type: "button",
    onClick: onToggle,
    onKeyDown: onToggle
  }, trip.originalPrice && __jsx(_Special.default, {
    color: theme.colors.primary['80']
  }), trip.specialId && !trip.originalPrice && __jsx(_Package.default, {
    color: theme.colors.primary['80']
  }), !trip.specialId && !trip.originalPrice && __jsx(_InformationCircle.default, null)) : null)), __jsx(_StickyBarBalloon.default, {
    trip: trip,
    open: open,
    onClose: onClose
  })), __jsx(_Stack.default, {
    spacing: 2,
    variant: "inline",
    alignItems: "baseline"
  }, __jsx(_Body.default, {
    variant: "small",
    className: "gt-xl"
  }, __jsx(_BookingText.default, {
    booking: _objectSpread(_objectSpread({}, booking), {}, {
      type: 'static'
    })
  })), __jsx(_Body.default, {
    variant: "small",
    className: "gt-s lt-xl"
  }, __jsx(_BookingText.default, {
    onlyShowPersons: true,
    booking: _objectSpread(_objectSpread({}, booking), {}, {
      type: 'static'
    })
  })), !plannerIsOpen && __jsx(_StickyBar.StickyBarTextButton, {
    size: "tiny",
    onClick: openModal,
    showBorderAlways: false
  }, __jsx("span", {
    className: "gt-s"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "ujUeDf",
    defaultMessage: [{
      "type": 0,
      "value": "aanpassen"
    }]
  })), __jsx("span", {
    className: "lt-s"
  }, __jsx(_BookingText.default, {
    booking: _objectSpread(_objectSpread({}, booking), {}, {
      type: 'static'
    })
  }))))));
};
exports.TripContent = TripContent;
var _default = exports.default = UnitStickyBar;
const StyledStickyBar = (0, _styledComponents.default)(_StickyBar.default).withConfig({
  displayName: "UnitStickyBar__StyledStickyBar",
  componentId: "zhq565-0"
})(["", ";z-index:", ";"], ({
  plannerIsOpen,
  withTransition,
  visible
}) => withTransition ? (0, _styledComponents.css)(["@media screen and (max-width:", "px){transform:", ";}transform:", ";"], ({
  theme
}) => theme.mediaQueriesValues.s - 1, plannerIsOpen && visible ? 'translateY(0rem)' : 'translateY(calc(var(--booking-bar-height) + 2px))', plannerIsOpen ? 'translateY(0rem)' : 'translateY(calc(var(--booking-bar-height) + 2px))') : (0, _styledComponents.css)(["@media screen and (max-width:", "px){transform:", ";transition:transform 200ms ease-in-out;}"], ({
  theme
}) => theme.mediaQueriesValues.s - 1, !visible && plannerIsOpen ? 'translateY(var(--booking-bar-height))' : 'translateY(0rem)'), ({
  theme,
  plannerIsOpen
}) => plannerIsOpen ? theme.zIndices.modal + 1 : theme.zIndices.sticky);
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Special = _interopRequireDefault(require("@oberoninternal/travelbase-ds/svg/Special.svg"));
var _Package = _interopRequireDefault(require("@oberoninternal/travelbase-ds/svg/Package.svg"));
var _validInterval = _interopRequireDefault(require("@oberoninternal/travelbase-ds/utils/validInterval"));
var _addDays = _interopRequireDefault(require("date-fns/addDays"));
var _differenceInCalendarDays = _interopRequireDefault(require("date-fns/differenceInCalendarDays"));
var _isBefore = _interopRequireDefault(require("date-fns/isBefore"));
var _isSameDay = _interopRequireDefault(require("date-fns/isSameDay"));
var _isWithinInterval = _interopRequireDefault(require("date-fns/isWithinInterval"));
var _startOfToday = _interopRequireDefault(require("date-fns/startOfToday"));
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _parseToDateString = _interopRequireDefault(require("../../constants/parseToDateString"));
var _graphql = require("../../generated/graphql");
var _trip = require("../../utils/trip");
var _isAfter = _interopRequireDefault(require("date-fns/isAfter"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const containsDiscount = trip => !!(trip.type !== _graphql.TripTypeEnum.Regular && trip.originalPrice && trip.price < trip.originalPrice);
const containsUpsell = trip => trip.type !== _graphql.TripTypeEnum.Regular && (!trip.originalPrice || trip.originalPrice && trip.price > trip.originalPrice);
const getDayIcons = (discountAvailable, upsellAvailable) => __jsx(_react.default.Fragment, null, discountAvailable && __jsx(_Special.default, null), upsellAvailable && __jsx(_Package.default, null));
const usePlannerModifier = (state, trips) => {
  const intl = (0, _reactIntl.useIntl)();
  const longestTrips = (0, _react.useMemo)(() => [...trips.entries()].map(([, dayTrips]) => [...dayTrips].sort((a, b) => b.duration - a.duration)[0]), [trips]);
  const {
    period,
    selecting
  } = state;
  const getDayTrips = (0, _react.useCallback)(date => trips.get((0, _parseToDateString.default)(date)) ?? [], [trips]);
  // These trips are sorted by price
  const potentialDayTrips = (0, _react.useMemo)(() => period?.start ? [...getDayTrips(period.start)].sort((a, b) => a.price - b.price) : undefined, [getDayTrips, period]);
  const formatPrice = (0, _react.useCallback)(price => intl.formatNumber(price, {
    format: 'EUR_ROUNDED'
  }), [intl]);
  const computeDayProps = (0, _react.useCallback)(day => {
    const {
      start,
      end
    } = period ?? {};
    if ((0, _isBefore.default)(day, (0, _startOfToday.default)())) {
      return {
        variant: 'disabled'
      };
    }
    const dayTrips = getDayTrips(day);
    if (start && (0, _isSameDay.default)(day, start)) {
      const discountAvailable = dayTrips.some(containsDiscount);
      const upsellAvailable = dayTrips.some(containsUpsell);
      return {
        variant: 'selected-start',
        icon: getDayIcons(discountAvailable, upsellAvailable)
      };
    }
    if (start && end && (0, _isSameDay.default)(day, end) && potentialDayTrips) {
      const sameDurationTrips = potentialDayTrips.filter(({
        duration
      }) => (0, _differenceInCalendarDays.default)(end, start) === duration);
      const discountAvailable = sameDurationTrips.some(containsDiscount);
      const upsellAvailable = dayTrips.some(containsUpsell);
      const [bestTrip] = sameDurationTrips;
      const label = bestTrip ? formatPrice(bestTrip.price) : undefined;
      return {
        variant: 'selected-end',
        label,
        icon: getDayIcons(discountAvailable, upsellAvailable)
      };
    }
    if (selecting && start && potentialDayTrips) {
      for (const {
        duration,
        price
      } of potentialDayTrips) {
        const label = formatPrice(price);
        const discountAvailable = potentialDayTrips.some(trip => trip.duration === duration && containsDiscount(trip));
        const upsellAvailable = dayTrips.some(containsUpsell);
        const potential = (0, _addDays.default)(start, duration);
        if ((0, _isSameDay.default)(potential, day)) {
          if (end && (0, _isWithinInterval.default)(day, {
            start,
            end
          })) {
            return {
              variant: 'bookable-within',
              label,
              icon: getDayIcons(discountAvailable, upsellAvailable)
            };
          }
          return {
            variant: 'bookable-outside',
            label,
            icon: getDayIcons(discountAvailable, upsellAvailable)
          };
        }
      }
    }
    if ((0, _validInterval.default)(period) && (0, _isAfter.default)(period.end, period.start) && (0, _isWithinInterval.default)(day, period)) {
      const discountAvailable = dayTrips.some(containsDiscount);
      const upsellAvailable = dayTrips.some(containsUpsell);
      return {
        variant: !selecting && dayTrips.length > 0 ? 'bookable-within' : 'within',
        icon: getDayIcons(discountAvailable, upsellAvailable)
      };
    }
    if (!selecting) {
      const discountAvailable = dayTrips.some(containsDiscount);
      const upsellAvailable = dayTrips.some(containsUpsell);
      if (dayTrips.length > 0) {
        return {
          variant: 'bookable',
          icon: getDayIcons(discountAvailable, upsellAvailable)
        };
      }
    }
    if (longestTrips.some(trip => {
      const {
        arrivalDate,
        departureDate
      } = (0, _trip.getDateStringsFromTrip)(trip);
      return (0, _isWithinInterval.default)(day, {
        start: new Date(arrivalDate),
        end: new Date(departureDate)
      });
    })) {
      return {
        variant: 'default'
      };
    }
    return {
      variant: 'blocked'
    };
  }, [formatPrice, getDayTrips, period, potentialDayTrips, selecting, longestTrips]);
  return computeDayProps;
};
var _default = exports.default = usePlannerModifier;
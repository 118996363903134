"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _Title = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Title"));
var _grid = require("@rebass/grid");
var _addDays = _interopRequireDefault(require("date-fns/addDays"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _graphql = require("../generated/graphql");
var _Heading = _interopRequireDefault(require("./designsystem/Heading"));
var _SpecialTrip = _interopRequireDefault(require("./SpecialTrip"));
var _Stack = _interopRequireDefault(require("./Stack"));
var __jsx = _react.default.createElement;
const AvailableSpecials = ({
  trips = [],
  booking,
  setBooking
}) => {
  const filteredTrips = trips.filter(trip => {
    if (trip.type === _graphql.TripTypeEnum.Regular) {
      return false;
    }
    if (booking.trip?.specialId && trip.specialId !== booking.trip.specialId) {
      return false;
    }
    return true;
  });
  if (!filteredTrips?.length) {
    return null;
  }
  const [firstTrip] = filteredTrips;
  const onAddSpecialTrip = trip => {
    setBooking({
      trip
    });
  };
  const onRemoveSpecialTrip = () => {
    // we want the best trip to be selected again.
    setBooking({
      trip: trips[0]
    });
  };
  return __jsx(_Stack.default, {
    spacing: 4,
    mt: 5,
    className: "gt-s"
  }, __jsx(_grid.Flex, {
    flexDirection: ['column', null, null, 'row']
  }, __jsx(_grid.Box, {
    style: {
      flexShrink: 0
    },
    width: [1, null, null, '24rem', '32.8rem'],
    pr: 3
  }, __jsx(_Heading.default, {
    mt: 5
  }, __jsx(_Title.default, null, __jsx(_reactIntl.FormattedMessage, {
    id: "9JmIVd",
    defaultMessage: [{
      "type": 0,
      "value": "Korting"
    }]
  })), __jsx(_Body.default, {
    style: {
      marginTop: '8px'
    }
  }, __jsx(_reactIntl.FormattedDate, {
    value: firstTrip.date,
    day: "numeric",
    month: "short"
  }), ' — ', __jsx(_reactIntl.FormattedDate, {
    value: (0, _addDays.default)(new Date(firstTrip.date), firstTrip.duration),
    day: "numeric",
    month: "short"
  })))), __jsx(_Stack.default, {
    spacing: 4,
    flex: 1
  }, filteredTrips.map((trip, i) => __jsx(_SpecialTrip.default, {
    trip: trip,
    key: i,
    booking: booking,
    onAddSpecialTrip: onAddSpecialTrip,
    onRemoveSpecialTrip: onRemoveSpecialTrip
  })))));
};
var _default = exports.default = AvailableSpecials;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.StyledCloseButton = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _UnreachableCaseError = require("@oberoninternal/travelbase-ds/entities/UnreachableCaseError");
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _SvgButton = _interopRequireDefault(require("./designsystem/SvgButton"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const CloseButton = props => __jsx(StyledCloseButton, (0, _extends2.default)({
  type: "button"
}, props), __jsx(Cross, null));
var _default = exports.default = CloseButton;
const StyledCloseButton = exports.StyledCloseButton = (0, _styledComponents.default)(_SvgButton.default).withConfig({
  displayName: "CloseButton__StyledCloseButton",
  componentId: "sc-1w7xi1j-0"
})(["position:absolute;", ";height:2.4rem;width:2.4rem;"], ({
  variant = 'regular'
}) => {
  switch (variant) {
    case 'regular':
      return (0, _styledComponents.css)(["top:2.4rem;right:2.4rem;"]);
    case 'small':
      return (0, _styledComponents.css)(["top:1.2rem;right:1.2rem;"]);
    default:
      throw new _UnreachableCaseError.UnreachableCaseError(variant);
  }
});

// TODO: Somehow conflicting ID's mess up svg's, so for now we define the Cross like this.
const Cross = () => __jsx("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: "18",
  height: "18",
  fill: "currentColor"
}, __jsx("defs", null, __jsx("filter", {
  id: "xaxaxaxaxaaXAXAX",
  width: "147.1%",
  height: "200%",
  x: "-23.6%",
  y: "-40.9%",
  filterUnits: "objectBoundingBox"
}, __jsx("feOffset", {
  dy: "24",
  in: "SourceAlpha",
  result: "shadowOffsetOuter1"
}), __jsx("feGaussianBlur", {
  in: "shadowOffsetOuter1",
  result: "shadowBlurOuter1",
  stdDeviation: "40"
}), __jsx("feColorMatrix", {
  in: "shadowBlurOuter1",
  result: "shadowMatrixOuter1",
  values: "0 0 0 0 0.229949097 0 0 0 0 0.461254077 0 0 0 0 0.62647192 0 0 0 0.0533333333 0"
}), __jsx("feOffset", {
  dy: "16",
  in: "SourceAlpha",
  result: "shadowOffsetOuter2"
}), __jsx("feGaussianBlur", {
  in: "shadowOffsetOuter2",
  result: "shadowBlurOuter2",
  stdDeviation: "12"
}), __jsx("feColorMatrix", {
  in: "shadowBlurOuter2",
  result: "shadowMatrixOuter2",
  values: "0 0 0 0 0.231372549 0 0 0 0 0.462745098 0 0 0 0 0.62745098 0 0 0 0.0266666667 0"
}), __jsx("feMerge", null, __jsx("feMergeNode", {
  in: "shadowMatrixOuter1"
}), __jsx("feMergeNode", {
  in: "shadowMatrixOuter2"
})))), __jsx("g", {
  fill: "none"
}, __jsx("g", {
  transform: "translate(-511 -19)"
}, __jsx("use", {
  fill: "#000",
  filter: "url(#xaxaxaxaxaaXAXAX)"
}), __jsx("use", {
  fill: "#FFF"
})), __jsx("path", {
  stroke: "currentColor",
  d: "m1.5 16.5 15-15m0 15-15-15"
})));
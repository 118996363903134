"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _UnreachableCaseError = require("@oberoninternal/travelbase-ds/entities/UnreachableCaseError");
var _validInterval = _interopRequireDefault(require("@oberoninternal/travelbase-ds/utils/validInterval"));
var _compareAsc = _interopRequireDefault(require("date-fns/compareAsc"));
var _isSameDay = _interopRequireDefault(require("date-fns/isSameDay"));
var _react = require("react");
var _useOnEscape = _interopRequireDefault(require("../useOnEscape"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const initialPeriodState = {
  selecting: false,
  period: null
};
const dayIsPeriodDay = (day, period) => (0, _isSameDay.default)(period.start, day) || (0, _isSameDay.default)(period.end, day);
function periodReducer(state = initialPeriodState, action) {
  const {
    period,
    selecting
  } = state;
  switch (action.type) {
    case 'click':
      {
        const {
          day
        } = action;
        if (!period || (0, _validInterval.default)(period) && !dayIsPeriodDay(day, period) && !selecting) {
          return _objectSpread(_objectSpread({}, state), {}, {
            period: {
              start: day,
              end: undefined
            },
            selecting: true
          });
        }
        if (period.start && (0, _isSameDay.default)(period.start, day)) {
          return _objectSpread(_objectSpread({}, state), {}, {
            period: null,
            selecting: false
          });
        }
        if (period.end && (0, _isSameDay.default)(period.end, day) && !selecting) {
          return _objectSpread(_objectSpread({}, state), {}, {
            selecting: true
          });
        }
        return _objectSpread(_objectSpread({}, state), {}, {
          period: _objectSpread(_objectSpread({}, period), {}, {
            end: day
          }),
          selecting: false
        });
      }
    case 'hover':
      {
        const {
          day
        } = action;
        if (selecting && period?.start) {
          const [start, end] = [period.start, day].sort(_compareAsc.default);
          return _objectSpread(_objectSpread({}, state), {}, {
            period: {
              start,
              end
            }
          });
        }
        return state;
      }
    case 'set':
      return _objectSpread(_objectSpread({}, state), action.state);
    case 'clear':
      return initialPeriodState;
    case 'reset':
      return _objectSpread(_objectSpread({}, initialPeriodState), action.state);
    default:
      throw new _UnreachableCaseError.UnreachableCaseError(action);
  }
}
const usePlannerPeriod = optionalInitialPeriod => {
  const initialPeriod = (0, _react.useMemo)(() => optionalInitialPeriod ? {
    period: optionalInitialPeriod
  } : initialPeriodState, [optionalInitialPeriod]);
  const {
    0: state,
    1: dispatch
  } = (0, _react.useReducer)(periodReducer, initialPeriod);
  const set = (0, _react.useCallback)(newState => {
    dispatch({
      state: newState,
      type: 'set'
    });
  }, []);
  const reset = (0, _react.useCallback)(() => {
    dispatch({
      state: initialPeriod,
      type: 'reset'
    });
  }, [initialPeriod]);
  const clear = (0, _react.useCallback)(() => {
    dispatch({
      type: 'clear'
    });
  }, []);
  const onClick = (0, _react.useCallback)(({
    day
  }) => dispatch({
    type: 'click',
    day
  }), []);
  const onHover = (0, _react.useCallback)(({
    day
  }) => dispatch({
    type: 'hover',
    day
  }), []);
  (0, _useOnEscape.default)(() => {
    if (state.selecting) {
      clear();
    }
  });
  return {
    state,
    onClick,
    onHover,
    reset,
    set,
    clear,
    initialState: initialPeriod
  };
};
var _default = exports.default = usePlannerPeriod;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.decodeNumber = decodeNumber;
exports.default = void 0;
var _useQueryParams = require("use-query-params");
// 🚨 at the moment of writing this I'm sitting here angry AF because the library we're using is failing miserably at doing its job....
// see https://github.com/pbeshai/use-query-params/issues/175 for more info

/**
 * Decodes a number from a string. If the number is invalid,
 * it returns undefined.
 *
 * If an array is provided, only the first entry is used.
 *
 * @param {String} input the encoded number string
 * @return {Number} the number value
 */
function decodeNumber(input) {
  if (input == null) {
    return undefined;
  }
  const numStr = input instanceof Array ? input[0] : input;
  if (numStr == null || numStr === '') {
    return undefined;
  }
  const result = +numStr;

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(result)) {
    return undefined;
  }
  return result;
}
const NumberParam = {
  encode: _useQueryParams.encodeNumber,
  decode: decodeNumber
};
var _default = exports.default = NumberParam;
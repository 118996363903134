"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _useQueryParams = require("use-query-params");
const predicate = val => Boolean(val);
const ArrayParam = {
  encode: array => (0, _useQueryParams.encodeArray)(array)?.filter(predicate),
  decode: array => (0, _useQueryParams.decodeArray)(array)?.filter(predicate)
};
var _default = exports.default = ArrayParam;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _RoundButton = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/RoundButton"));
var _TextButton = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/TextButton"));
var _ArrowLeft = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/ArrowLeft"));
var _ArrowRight = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/figure/ArrowRight"));
var _useIntervalClick = _interopRequireDefault(require("@oberoninternal/travelbase-ds/hooks/useIntervalClick"));
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _Planner = require("./Planner");
const _excluded = ["children", "loading"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const PlannerControls = _ref => {
  let {
      children,
      loading
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const plannerViewActions = (0, _react.useContext)(_Planner.PlannerViewActionsContext);
  const plannerViewState = (0, _react.useContext)(_Planner.PlannerViewContext);
  if (!plannerViewActions || !plannerViewState) {
    throw new Error('PlannerControls can only be used within a Planner component');
  }
  const {
    scrollBy,
    scrollToToday,
    getMonthDimensions
  } = plannerViewActions;
  const {
    canGoBackwards,
    canGoForwards
  } = plannerViewState;
  const useScrollByMonthWidth = (positive = true) => (0, _useIntervalClick.default)(() => {
    const monthWidth = getMonthDimensions();
    scrollBy(positive ? monthWidth : -monthWidth, !loading);
  }, 500);
  const incrementScrollProps = useScrollByMonthWidth();
  const decrementScrollProps = useScrollByMonthWidth(false);
  return __jsx(_Planner.PlannerControlsWrapper, props, children, __jsx(Controls, null, __jsx(_TextButton.default, {
    onClick: () => scrollToToday(!loading),
    disabled: !canGoBackwards
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "q9hRlN",
    defaultMessage: [{
      "type": 0,
      "value": "Vandaag"
    }]
  })), __jsx(StyledRoundButton, (0, _extends2.default)({
    variant: "outline",
    size: "large",
    disabled: !canGoBackwards
  }, decrementScrollProps), __jsx(_ArrowLeft.default, null)), __jsx(StyledRoundButton, (0, _extends2.default)({
    variant: "outline",
    size: "large",
    disabled: !canGoForwards
  }, incrementScrollProps), __jsx(_ArrowRight.default, null))));
};
var _default = exports.default = PlannerControls;
const Controls = _styledComponents.default.div.withConfig({
  displayName: "PlannerControls__Controls",
  componentId: "sc-1pw3b2k-0"
})(["display:none;@media screen and (min-width:", "){display:flex;}align-items:center;margin-left:auto;> button + button{margin-left:", ";}"], ({
  theme
}) => theme.mediaQueries.s, ({
  theme
}) => theme.spacing['30_Small']);
const StyledRoundButton = (0, _styledComponents.default)(_RoundButton.default).withConfig({
  displayName: "PlannerControls__StyledRoundButton",
  componentId: "sc-1pw3b2k-1"
})(["opacity:", ";"], ({
  disabled
}) => disabled ? '0.4' : '1');
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _theme = require("@oberoninternal/travelbase-ds/constants/theme");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styledSystem = require("styled-system");
/**
 * TODO: Migrate this to the designsystem
 */
const Anchor = _styledComponents.default.button.attrs(({
  as,
  type
}) => ({
  type: as ? type : 'button'
})).withConfig({
  displayName: "Anchor",
  componentId: "sc-1d7ec2e-0"
})(["color:", " !important;text-decoration:none;text-align:start;text-underline-offset:3px;padding:0;margin:0;font:inherit;font-weight:500;cursor:pointer;:hover{text-decoration:underline !important;color:", " !important;}", ";"], ({
  theme
}) => (0, _theme.getAssignmentColor)(theme, theme.colorAssignments.textAction), ({
  theme
}) => (0, _theme.getAssignmentColor)(theme, theme.colorAssignments.textAction, 1), _styledSystem.space);
var _default = exports.default = Anchor;
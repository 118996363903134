"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _reactIntl = require("react-intl");
const pricingStructureMessages = (0, _reactIntl.defineMessages)({
  accommodation: {
    "id": "BmL592",
    "defaultMessage": [{
      "type": 0,
      "value": "Verblijfskosten"
    }]
  },
  lastMinuteDiscount: {
    "id": "OHYWdO",
    "defaultMessage": [{
      "type": 0,
      "value": "Lastminute korting"
    }]
  },
  earlyDiscount: {
    "id": "Vuz1Pn",
    "defaultMessage": [{
      "type": 0,
      "value": "Vroegboekkorting"
    }]
  },
  earlyDiscountDescription: {
    "id": "zqKsWY",
    "defaultMessage": [{
      "type": 0,
      "value": "Met de vroegboekkorting pak je veel voordeel al heel vroeg boekt."
    }]
  },
  lastMinuteDiscountDescription: {
    "id": "EB/Y1D",
    "defaultMessage": [{
      "type": 0,
      "value": "Met de lastminute korting pak je veel voordeel doordat je op het laatste moment boekt."
    }]
  },
  touristTax: {
    "id": "LaAI/c",
    "defaultMessage": [{
      "type": 0,
      "value": "Toeristenbelasting"
    }]
  },
  bookingFee: {
    "id": "FG8Zgm",
    "defaultMessage": [{
      "type": 0,
      "value": "Reserveringskosten"
    }]
  },
  cancellationInsurance: {
    "id": "MW/+Fd",
    "defaultMessage": [{
      "type": 0,
      "value": "Annuleringsverzekering"
    }]
  },
  policyCosts: {
    "id": "kcT9dm",
    "defaultMessage": [{
      "type": 0,
      "value": "Administratiekosten"
    }]
  },
  contentsInsurance: {
    "id": "aZWcbT",
    "defaultMessage": [{
      "type": 0,
      "value": "Inventarisverzekering"
    }]
  },
  insuranceTax: {
    "id": "Nagw8e",
    "defaultMessage": [{
      "type": 0,
      "value": "Verzekeringsbelasting"
    }]
  }
});
var _default = exports.default = pricingStructureMessages;
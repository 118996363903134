"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Container = exports.Arrow = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _dynamic = _interopRequireDefault(require("next/dynamic"));
var _react = _interopRequireDefault(require("react"));
var _styledComponents = _interopRequireDefault(require("styled-components"));
const _excluded = ["containerProps", "children", "ArrowComponent"];
var __jsx = _react.default.createElement;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const Popper = (0, _dynamic.default)(() => Promise.resolve().then(() => _interopRequireWildcard(require('react-popper'))).then(mod => mod.Popper), {
  ssr: false,
  loadableGenerated: {
    webpack: () => [require.resolveWeak('react-popper')]
  }
});
const Tooltip = _ref => {
  let {
      containerProps = {},
      children,
      ArrowComponent
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return __jsx(Popper, props, ({
    ref,
    style,
    placement,
    arrowProps
  }) => __jsx(Container, (0, _extends2.default)({}, containerProps, {
    style: _objectSpread(_objectSpread({}, containerProps.style), style),
    ref: ref
  }), ArrowComponent ? __jsx(ArrowComponent, (0, _extends2.default)({}, arrowProps, {
    "data-placement": placement
  })) : __jsx(Arrow, (0, _extends2.default)({}, arrowProps, {
    "data-placement": placement
  })), children));
};
var _default = exports.default = Tooltip;
const Arrow = exports.Arrow = _styledComponents.default.div.withConfig({
  displayName: "Tooltip__Arrow",
  componentId: "sc-1qvswqn-0"
})(["position:absolute;width:3em;height:3em;display:flex;--backgroundColor:", ";&[data-placement*='bottom']{top:0;left:0;margin-top:-2.8rem;&::before{border-width:0 0.8rem 1em 0.8rem;border-color:transparent transparent var(--backgroundColor) transparent;}}&[data-placement*='top']{bottom:0;left:0;margin-bottom:-2.9em;&::before{border-width:1em 0.8rem 0 0.8rem;border-color:var(--backgroundColor) transparent transparent transparent;}}&[data-placement*='right']{left:0;margin-left:-3.2rem;&::before{border-width:0.8rem 1em 0.8rem 0;border-color:transparent var(--backgroundColor) transparent transparent;}}&[data-placement*='left']{right:0;margin-right:-2.8rem;&::before{border-width:0.8rem 0 0.8rem 1em;border-color:transparent transparent transparent var(--backgroundColor);}}&::before{content:'';margin:auto;display:block;width:0;height:0;border-style:solid;}"], ({
  theme
}) => theme.colors.neutral['100']);
const Container = exports.Container = _styledComponents.default.div.withConfig({
  displayName: "Tooltip__Container",
  componentId: "sc-1qvswqn-1"
})(["opacity:0.8;color:white;width:30rem;padding:1.6rem;background:", ";border-radius:0.8rem;z-index:9;"], ({
  theme
}) => theme.colors.neutral['100']);
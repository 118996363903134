"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ModalHeader = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/ModalHeader"));
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _pricingStructureMessages = _interopRequireDefault(require("../constants/pricingStructureMessages"));
var _graphql = require("../generated/graphql");
var _Popover = _interopRequireDefault(require("./designsystem/Popover"));
var _Stack = _interopRequireDefault(require("./Stack"));
var __jsx = _react.default.createElement;
const StickyBarBalloon = ({
  trip,
  open,
  onClose
}) => {
  // query will be skipped if there's no specialId, hence the assertion
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const {
    data,
    loading
  } = (0, _graphql.useSpecialQuery)({
    variables: {
      id: trip?.specialId
    },
    skip: !trip?.specialId
  });
  const {
    description,
    name,
    landingPageUrl
  } = data?.special ?? {};
  if (!trip || loading) {
    return null;
  }
  const {
    type
  } = trip;
  return __jsx(StyledPopover, {
    placement: "top",
    open: open,
    onClose: onClose
  }, type === _graphql.TripTypeEnum.Regular && __jsx(_react.default.Fragment, null, __jsx(_ModalHeader.default, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "+CJ+Au",
    defaultMessage: [{
      "type": 0,
      "value": "Prijs inclusief"
    }]
  })), __jsx(_Stack.default, {
    mt: 4,
    spacing: 4,
    style: {
      textAlign: 'center'
    }
  }, __jsx(_Body.default, null, __jsx(_reactIntl.FormattedMessage, {
    id: "VtKuYt",
    defaultMessage: [{
      "type": 0,
      "value": "Verplichte bijkomende kosten"
    }]
  })), __jsx(_Body.default, null, __jsx(_reactIntl.FormattedMessage, _pricingStructureMessages.default.touristTax)), __jsx(_Body.default, null, __jsx(_reactIntl.FormattedMessage, _pricingStructureMessages.default.bookingFee)))), type === _graphql.TripTypeEnum.LastMinute && __jsx(_react.default.Fragment, null, __jsx(_ModalHeader.default, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedMessage, _pricingStructureMessages.default.lastMinuteDiscount)), __jsx(_Stack.default, {
    mt: 4,
    spacing: 4
  }, __jsx(_Body.default, null, __jsx(_reactIntl.FormattedMessage, _pricingStructureMessages.default.lastMinuteDiscountDescription)))), type === _graphql.TripTypeEnum.EarlyBooking && __jsx(_react.default.Fragment, null, __jsx(_ModalHeader.default, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedMessage, _pricingStructureMessages.default.earlyDiscount)), __jsx(_Stack.default, {
    mt: 4,
    spacing: 4
  }, __jsx(_Body.default, null, __jsx(_reactIntl.FormattedMessage, _pricingStructureMessages.default.earlyDiscountDescription)))), type === _graphql.TripTypeEnum.Special && __jsx(_react.default.Fragment, null, __jsx(_ModalHeader.default, {
    variant: "small"
  }, name), __jsx(_Stack.default, {
    mt: 4,
    spacing: 4
  }, __jsx(_Body.default, null, description, ' ', landingPageUrl && __jsx(_react.default.Fragment, null, __jsx("br", null), __jsx("a", {
    href: landingPageUrl,
    target: "_blank",
    rel: "noopener noreferrer"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "EbRgER",
    defaultMessage: [{
      "type": 0,
      "value": "Lees meer"
    }]
  })))))));
};
var _default = exports.default = StickyBarBalloon;
const StyledPopover = (0, _styledComponents.default)(_Popover.default).withConfig({
  displayName: "StickyBarBalloon__StyledPopover",
  componentId: "w6kbo8-0"
})(["@media screen and (max-width:", "px){max-width:80vw;}"], ({
  theme
}) => theme.mediaQueriesValues.s - 1);
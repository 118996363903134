"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _dynamic = _interopRequireDefault(require("next/dynamic"));
var _react = _interopRequireWildcard(require("react"));
var _reactSpring = require("react-spring");
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _CloseButton = _interopRequireDefault(require("../CloseButton"));
const _excluded = ["onClose", "open", "children", "className", "transform"];
var __jsx = _react.default.createElement;
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const Popper = (0, _dynamic.default)(() => Promise.resolve().then(() => _interopRequireWildcard(require('react-popper'))).then(mod => mod.Popper), {
  ssr: false,
  loadableGenerated: {
    webpack: () => [require.resolveWeak('react-popper')]
  }
});
const Popover = _ref => {
  let {
      onClose,
      open,
      children,
      className,
      transform = {
        from: 'translateY(0rem)',
        to: 'translateY(-2.4rem)'
      }
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const {
    from,
    to
  } = transform;
  const transitions = (0, _reactSpring.useTransition)(open, {
    from: {
      opacity: 0,
      transform: from
    },
    enter: {
      opacity: 1,
      transform: to
    },
    leave: {
      opacity: 0,
      transform: from
    }
  });
  const closeRef = (0, _react.useRef)(null);
  const {
    0: hasFocus,
    1: setHasFocus
  } = (0, _react.useState)(false);
  const onFocus = () => setHasFocus(true);

  // For a11y reasons focus the close button after opening
  (0, _react.useEffect)(() => {
    if (open && !hasFocus) {
      closeRef.current?.focus();
    }
    if (!open) {
      setHasFocus(false);
    }
  }, [open, hasFocus]);
  return __jsx(_react.default.Fragment, null, transitions((styleProps, item) => item && __jsx(Popper, props, ({
    ref,
    style,
    placement
  }) => __jsx(PopperContent, {
    ref: ref,
    style: style,
    "data-placement": placement,
    open: open
  }, __jsx(AnimatedContainer, {
    style: styleProps,
    className: className
  }, __jsx(_CloseButton.default, {
    onClick: onClose,
    ref: closeRef,
    onFocus: onFocus
  }), children)))));
};
var _default = exports.default = Popover;
const PopperContent = _styledComponents.default.div.withConfig({
  displayName: "Popover__PopperContent",
  componentId: "c5uun3-0"
})(["z-index:", ";", ""], ({
  theme
}) => theme.zIndices.popover, ({
  open
}) => !open && (0, _styledComponents.css)(["pointer-events:none;"]));
const Container = _styledComponents.default.div.withConfig({
  displayName: "Popover__Container",
  componentId: "c5uun3-1"
})(["padding:2.4rem;background:", ";box-shadow:0 1.6rem 2.4rem 0 rgba(59,118,160,0.03),0 2.4rem 8rem 0 rgba(59,118,160,0.05);border-radius:0.8rem;width:100vw;@media screen and (min-width:", "){width:33rem;}"], ({
  theme
}) => theme.colors.neutral['0'], ({
  theme
}) => theme.mediaQueries.s);
const AnimatedContainer = (0, _reactSpring.animated)(Container);